import Vue from 'vue'

export default Vue.extend({
  name: 'XpProgressBar',
  props: {
    color: {
      type: String
    },
    height: {
      type: String,
      default: '5px'
    },
    value: {
      type: Number,
      default: null
    }
  },
  computed: {
    ariaLoading () {
      if (this.value === 0) { return this.$t('global.loading', ['']) }
      return this.$t('global.loading', [`${this.value}%`])
    }
  },
  mounted () {
    setTimeout(() => {
      this.shouldNotifyLoadingToSr = true
    }, 1000)
  },
  render (h) {
    return h('div', {
      class: 'c-xp-progress-bar',
      style: { height: this.height, color: this.color }
    }, [
      h('div', { attrs: { 'aria-hidden': true }, class: 'c-xp-progress-bar__line' }),
      h('div', { attrs: { 'aria-hidden': true }, class: 'c-xp-progress-bar__subline c-xp-progress-bar__subline--inc' }),
      h('div', { attrs: { 'aria-hidden': true }, class: 'c-xp-progress-bar__subline c-xp-progress-bar__subline--dec' }),
      h('div', { attrs: { 'aria-hidden': true }, class: 'c-xp-progress-bar__subline c-xp-progress-bar__subline--actual', style: `width: ${this.value}%` }),
      h('div', { class: 'sr-only' }, this.ariaLoading)
    ])
  }
})
